import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppServiceService } from "../../api/app-service.service";

@Component({
  selector: 'rating-component',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {

  @Input() readonly: boolean = false;
  @Input() rating: number = 0;
  @Output() onRatingChange: EventEmitter<any> = new EventEmitter();

  public totalStars = [1, 2, 3, 4, 5];

  constructor(public appService: AppServiceService) { }

  ngOnInit() { }

  /**
   * Will trigger when user click on the stars
   * @param rate rate number 
   */
  changeRate(rate) {
    if (!this.readonly) {
      this.rating = rate;
      this.onRatingChange.emit([this.rating]);
    }
  }

}
